import React from 'react';
import styled from 'styled-components';
import {
  Wrapper, Title, colors, media,
} from './style';
import { ArrowDown } from './icons';

const WrapperProjects = styled(Wrapper)`
  position: relative;
  background: ${colors.red};
  padding-bottom: 25px;
  padding-right: 40px;
  overflow: hidden;
  z-index: 2;
  ${Title} {
    margin-bottom: 10px;
    ${media.desktop`
      margin-bottom: 40px;
    `};
  }
`;

const Project = styled.div`
  display: block;
  color: ${colors.white};
  text-decoration: none;
  position: relative;
  font-family: Roboto;
  line-height: 20px;
  font-size: 17px;
  padding-bottom: 20px;
  font-weight: ${p => (p.new ? 'bold' : '300')};
  ${p => p.new && 'padding-right: 40px'};
  .whatWeDo {
    font-family: Ubuntu Mono;
    font-style: normal;
    line-height: 15px;
    font-size: 11px;
    text-transform: uppercase;
  }
  a {
    color: ${colors.white};
    text-decoration: none;
    display: inline-block;
  }
  ${media.desktop`
    line-height: 50px;
    font-size: 44px;
    padding-right: 123px;
    padding-bottom: 45px;
    .whatWeDo {
      line-height: 25px;
      font-size: 16px;
      padding-top: 10px;
    }
  `};
`;

const Svg = styled.svg`
  position: absolute;
  right: -15px;
  top: 40px;
  height: 150px;
  ${media.desktop`
    right: 90px;
    top: 30px;
    height: 377px;
  `};
`;

const NewIcon = () => (
  <Svg viewBox="0 0 409 377" xmlns="http://www.w3.org/2000/svg">
    <path
      id="svg_1"
      fill="#16161B"
      d="m12.856031,256.588999l87.124,-65.836l-99.98,-52.893l67.555,-102.8951l72.118,49.6901l-10.392,-82.7047l130.495,-1.9026l-0.362,63.5617l31.923,-31.7848l87.277,94.7654l-76.189,53.198l105.446,57.89l-67.556,102.895l-71.059,-47.992l9.334,81.007l-129.966,2.751l1.95,-72.332l-50.442,47.347l-87.276,-94.765z"
    />
    <path
      id="svg_2"
      fill="#E91921"
      d="m283.446031,145.134999c1.159,0.827 0.249,2.675 -1.103,2.261l-23.881,-7.06l13.43,18.394l-2.041,1.489l-15.664,-21.399c-0.662,-0.883 0.138,-2.345 1.406,-1.958l20.159,5.929l-15.498,-10.921l1.462,-2.068l21.73,15.333zm-10.822,-13.005l0.276,-0.937l-1.958,-9.404c-0.083,-0.414 0.028,-0.855 0.331,-1.159l12.217,-12.161c0.386,-0.386 0.937,-0.496 1.406,-0.303l13.403,5.791c0.965,0.358 0.91,1.737 0.22,2.206l-21.344,14.561l14.864,3.998l6.922,-6.921l1.792,1.792l-7.446,7.446c-0.331,0.331 -0.8,0.469 -1.213,0.331l-19.47,-5.24zm2.455,-3.006l20.103,-13.761l-10.506,-4.495l-11.114,11.059l1.517,7.197zm44.451,-19.411c0.827,1.103 -0.579,2.565 -1.765,1.765l-15.498,-10.7l10.727,15.471c0.8,1.186 -0.634,2.564 -1.765,1.765l-21.372,-15.416l1.489,-2.041l15.361,11.059l-10.121,-14.699c-0.8,-1.131 0.579,-2.62 1.792,-1.792l14.727,10.038l-11.086,-15.333l2.068,-1.462l15.443,21.345zm4.334,-10.346l-1.406,1.406l0.248,2.896l2.758,1.213l2.454,-2.454l-0.441,-2.096l-3.613,-0.965zm-1.434,-2.868l-2.151,1.379l-9.983,-15.499l2.151,-1.379l9.983,15.499z"
    />
  </Svg>
);

const MoreButton = styled.div`
  display: none;
  font-family: Roboto;
  font-weight: 300;
  line-height: 20px;
  font-size: 17px;

  color: ${colors.white};
  cursor: pointer;
  .arrowDown {
    width: 15px;
    path {
      stroke: ${colors.white};
    }
  }
  ${p => p.visible
    && `
    display: block;
  `};
  ${p => p.opened
    && `
    .arrowDown {
      transform: rotate(180deg);
    }
  `};
  ${media.desktop`
    .arrowDown {
      width: 36px;
    }
    line-height: 50px;
    font-size: 44px;
    padding-right: 123px;
    padding-bottom: 45px;
  `};
`;

export default class Projects extends React.Component {
  state = {
    opened: false,
  };

  render() {
    const { data } = this.props;
    const { opened } = this.state;
    const moreNumber = data.projects.slice(3).length;
    return (
      <WrapperProjects id="projects">
        <Title>{data.title}</Title>
        {data.new && <NewIcon />}
        {data.projects.slice(0, 3).map(({
          name, description, whatWeDo, url,
        }, index) => (
          <Project key={name} new={index === 0 && data.new}>
            <a href={url}>
              {name} // <span dangerouslySetInnerHTML={{ __html: description }} />
            </a>
            <div className="whatWeDo">
              <b>{data.whatWeDoPrefix}</b> {whatWeDo}
            </div>
          </Project>
        ))}
        {opened
          && data.projects.slice(3).map(({
            name, description, whatWeDo, url,
          }) => (
            <Project key={name}>
              <a href={url}>
                {name} // <span dangerouslySetInnerHTML={{ __html: description }} />
              </a>
              <div className="whatWeDo">
                <b>{data.whatWeDoPrefix}</b> {whatWeDo}
              </div>
            </Project>
          ))}
        <MoreButton
          onClick={() => this.setState(s => ({ opened: !s.opened }))}
          visible={moreNumber}
          opened={opened}
        >
          {data.allProjects} <ArrowDown className="arrowDown" /> {!opened && moreNumber}
        </MoreButton>
      </WrapperProjects>
    );
  }
}
