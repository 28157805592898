import Helmet from 'react-helmet';
import React, { Component } from 'react';

export default class Video extends Component {
  constructor(props) {
    super(props);
    this.video = React.createRef();
  }

  componentDidMount() {
    this.video.current.play();
  }

  render() {
    const { src } = this.props;
    return (
      <React.Fragment>
        <Helmet>
          <link as="video" rel="preload" href={src} crossOrigin="anonymous" />
        </Helmet>
        <video
          autoPlay
          ref={this.video}
          src={src}
          loop
          muted
          preLoad="none"
          playsInline
          crossOrigin="anonymous"
        />
      </React.Fragment>
    );
  }
}
