import React from 'react';
import styled from 'styled-components';
import { media, colors } from './style';

const Post = styled.a`
  display: block;
  color: ${colors.white};
  text-decoration: none;
  font-family: Roboto;
  font-weight: 300;
  line-height: 20px;
  font-size: 17px;
  flex: 1;

  padding-right: 20px;
  :last-child {
    padding-right: 0;
  }

  color: ${p => (p.achievement ? colors.red : colors.gray)};

  .type {
    font-family: Ubuntu Mono;
    font-style: normal;
    font-weight: normal;
    line-height: 15px;
    font-size: 11px;
    text-transform: uppercase;

    color: ${p => (p.achievement ? colors.red : colors.blue)};
  }

  .arrow {
    font-family: Poteha;
    line-height: 35px;
    font-size: 40px;
    text-align: center;

    color: ${colors.blue};
    vertical-align: text-top;
  }

  ${media.desktop`
    line-height: 40px;
    font-size: 30px;
    .type {
      line-height: 25px;
      font-size: 16px;
    }
    .arrow {
      line-height: normal;
      font-size: 110px;
      position: relative;
      top: -20px;
    }
    :hover .type, :hover .arrow, :hover {
      color: ${colors.red};
    }
  `};
`;

const WrapperBlog = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Blog = ({
  data, className, start = 0, number,
}) => (
  <WrapperBlog className={className}>
    {data.posts.slice(start, start + number).map(({ type, text, url }, i) => (
      // eslint-disable-next-line
      <Post key={text + i} href={url} achievement={type === 'achievement'}>
        <div className="type">{type}</div>
        {text}
        <span className="arrow">→</span>
      </Post>
    ))}
  </WrapperBlog>
);

const WrapperDesktop = styled.div`
  display: none;
  padding: 80px 90px 0px 35px;
  ${media.desktop`
    display: block;
  `};
  ${media.wide`
    display: none;
  `};
`;

const WrapperWide = styled.div`
  display: none;
  padding: 80px 90px 0px 35px;
  ${media.wide`
    display: block;
  `};
`;

export const BlogDesktop = props => (
  <WrapperDesktop>
    <Blog {...props} />
  </WrapperDesktop>
);

export const BlogWide = props => (
  <WrapperWide>
    <Blog {...props} />
  </WrapperWide>
);

export default Blog;
