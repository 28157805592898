import React from "react";
import styled, { css } from "styled-components";
import { Element, ScrollLink } from "react-scroll";
import rightLine from "../images/rightLine.png";

export const colors = {
  black: "#16161B",
  white: "#ffffff",
  blue: "#157EFA",
  red: "#E91921",
  gray: "#909090"
};

const desktopSize = 1000;
const wideSize = 1920;

export const media = {
  desktop: (...args) => css`
    @media (min-width: ${desktopSize}px) {
      ${css(...args)};
    }
  `,
  wide: (...args) => css`
    @media (min-width: ${wideSize}px) {
      ${css(...args)};
    }
  `
};

export const Wrapper = styled(Element)`
  padding: 30px 20px 0px;
  ${media.desktop`
    padding: 30px 90px 0px 35px;
  `};
`;

export const Title = styled.h1`
  font-family: Ubuntu Mono;
  font-style: normal;
  font-weight: normal;
  line-height: 30px;
  font-size: 28px;
  margin: 0;
  ${media.desktop`
    line-height: normal;
    font-size: 80px;
  `};
`;

export const Button = styled(({ disabled, sending, ...p }) => <div {...p} />)`
  background: ${colors.black};
  border: 1px solid ${colors.blue};
  box-sizing: border-box;
  box-shadow: 0px 3px 6px rgba(21, 126, 250, 0.9),
    inset 0px 0px 40px rgba(21, 126, 250, 0.9);
  border-radius: 4px;
  font-family: Ubuntu Mono;
  font-style: normal;
  font-weight: normal;
  line-height: 20px;
  padding: 4px 0px;
  font-size: 11px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  cursor: pointer;
  user-select: none;

  color: ${colors.blue};

  ${p =>
    p.disabled
      ? `
      box-shadow: none;
      border: 1px solid #505050;
      padding-top: 3px;
      padding-bottom: 3px;
      color: #505050;
      cursor: initial;
    `
      : css`
          ${media.desktop`
            :hover {
              color: ${colors.red};
              box-shadow: 0px 4px 8px rgba(233, 25, 33, 0.4),
                inset 0px 0px 40px rgba(233, 25, 33, 0.6);
              border: 2.2px solid ${colors.red};
            }
          `};
          :active {
            color: ${colors.red};
            box-shadow: 0px 4px 10px rgba(233, 25, 33, 0.8),
              inset 0px 0px 40px rgba(233, 25, 33, 0.8);
            border: 1px solid ${colors.red};
          }
          ${pr =>
            pr.sending &&
            `
              color: ${colors.red};
              box-shadow: 0px 4px 8px rgba(233, 25, 33, 0.4),
                inset 0px 0px 40px rgba(233, 25, 33, 0.6);
              border-color: ${colors.red};
          `};
        `};
  ${media.desktop`
    &, :active {
      display: inline-block;
      padding: 4px 30px;
      line-height: 40px;
      height: 50px;
      font-size: 24px;
      border-width: 2.2px;
    }
  `};
`;

const RightLineWrapper = styled.div`
  display: none;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 60px;
  :before {
    content: "";
    background-color: #505050;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 1;
  }
  :after {
    content: "";
    background-image: url(${rightLine});
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 3;
    background-position-y: calc(100% - 510px);
  }
  .wrapper {
    position: absolute;
    bottom: 500px;
    z-index: 4;
    transform: rotate(90deg);
    transform-origin: left bottom;
    .background {
      background-color: ${colors.black};
      padding-right: 200px;
    }
    .link {
      font-family: Poteha;
      line-height: normal;
      padding-bottom: 4px;
      font-size: 24px;
      color: ${colors.red};
      white-space: nowrap;
      text-decoration: none;
      display: block;
    }
  }
  ${media.desktop`
    display: block;
  `};
`;

export const RightLine = () => (
  <RightLineWrapper>
    <div className="wrapper" />
  </RightLineWrapper>
);

export const ButtonSend = styled(
  ScrollLink(({ onPress, ...p }) => <Button {...p} onClick={onPress} />)
).attrs({
  onPress: p => p.onClick
})`
  position: fixed;
  left: 20px;
  bottom: 20px;
  right: 20px;
  transition: all 200ms ease-in-out;
  opacity: 1;
  &.hidden {
    visibility: hidden;
    opacity: 0;
  }
  z-index: 5;

  ${media.desktop`
    display: none;
  `};
`;

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  ${media.desktop`
    background: #16161b;
    opacity: 0.8;
  `};
`;

export const Content = styled.div`
  ${p =>
    p.modal &&
    `
    filter: blur(4px);
  `};
`;
