import React, { useState } from 'react';
import styled from 'styled-components';

import {
  Wrapper, Title, colors, media,
} from './style';
import Video from './Video';
import { ArrowDown } from './icons';

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding-bottom: 20px;
  img {
    width: 130px;
    height: 160px;
    margin-bottom: 0;
  }
  .image {
    width: 130px;
    height: 160px;
    flex-shrink: 0;
    position: relative;
  }
  .image video {
    width: 100%;
    height: 100%;
  }
  .image .mask {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      95.27deg,
      #e91921 0%,
      #157efa 77.12%,
      #157efa 95.6%
    );
    mix-blend-mode: color;
  }
  ${media.desktop`
    padding-right: 60px;
    flex-direction: column;
    align-items: stretch;
    img, .image {
      width: 260px;
      height: 320px;
    }
    .image {
      margin-bottom: 10px;
    }
  `};
`;

const WrapperReviews = styled(Wrapper)`
  padding-top: 0;
  ${media.desktop`
    padding-top: 100px;
  `};
  ${Title} {
    padding-bottom: 10px;
    ${media.desktop`
      padding-bottom: 110px;
    `};
  }
  ${media.wide`
    .wrapper {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }
  `};
`;

const Review = styled.div`
  padding-bottom: 70px;

  ${media.desktop`
    display: flex;
    flex-direction: row;
    :nth-child(2n - 1) {
      flex-direction: row-reverse;
      ${Header} {
        padding-right: 0px;
        padding-left: 60px;
      }
    }
    padding-bottom: 135px;
    :last-child {
      padding-right: 300px;
    }
  `};

  ${media.wide`
    :nth-child(2n - 1) {
      padding-left: 0;
      flex-direction: row;
      ${Header} {
        padding-left: 0px;
        padding-right: 60px;
      }
    }
    :nth-child(2n) {
      padding-left: 50px;
      flex-direction: row-reverse;
      ${Header} {
        padding-right: 0px;
        padding-left: 60px;
      }
    }
    width: 50%;
    box-sizing: border-box;
    :last-child {
      padding-right: 0;
    }
    padding-bottom: 200px;
  `};
`;

const Name = styled.div`
  font-family: Ubuntu Mono;
  font-style: normal;
  font-weight: normal;
  line-height: 15px;
  font-size: 11px;
  text-transform: uppercase;
  padding-left: 20px;
  position: relative;
  bottom: -4px;
  ${media.desktop`
    bottom: 0px;
    padding-left: 0;
    line-height: 25px;
    font-size: 16px;
    br {
      display: none;
    }
  `};
`;

const Text = styled.div`
  position: relative;
  font-family: Roboto;
  font-weight: 300;
  line-height: 20px;
  font-size: 17px;

  color: ${colors.gray};
  b,
  strong {
    color: ${colors.white};
  }
  :before {
    content: "“";
    position: absolute;
    top: -60px;
    left: -6px;
    font-family: Roboto;
    font-weight: 300;
    line-height: normal;
    font-size: 100px;

    color: ${colors.gray};
  }
  :after {
    content: "”";
    position: absolute;
    left: -6px;
    bottom: -110px;
    font-family: Roboto;
    font-weight: 300;
    line-height: normal;
    font-size: 100px;

    color: ${colors.gray};
  }

  ${media.desktop`
    line-height: 40px;
    font-size: 30px;
    top: -10px;
    :before, :after {
      line-height: normal;
      font-size: 249px;
      letter-spacing: -0.01em;
      left: -15px;
    }
    :before {
      top: -144px;
    }
    :after {
      bottom: -259px;
    }
  `};
`;

const MoreButton = styled.div`
  display: none;
  font-family: Roboto;
  font-weight: 300;
  line-height: 20px;
  font-size: 17px;

  color: ${colors.white};
  cursor: pointer;
  .arrowDown {
    width: 15px;
    path {
      stroke: ${colors.white};
    }
  }
  ${p => p.visible
    && `
    display: block;
  `};
  ${p => p.opened
    && `
    .arrowDown {
      transform: rotate(180deg);
    }
  `};
  ${media.desktop`
    .arrowDown {
      width: 36px;
    }
    line-height: 50px;
    font-size: 44px;
    padding-right: 123px;
    padding-bottom: 45px;
  `};
`;

export default function Reviews({ title, data, allReviews }) {
  const [isOpen, setIsOpen] = useState(false);
  const moreNumber = data.slice(3).length;
  return (
    <WrapperReviews id="reviews">
      <Title>{title}</Title>
      <div className="wrapper">
        {data.slice(0, 3).map(
          ({
            node: {
              childMarkdownRemark: { frontmatter, html },
            },
          }) => (
            <Review key={frontmatter.name}>
              <Header>
                <div className="image">
                  <Video src={frontmatter.image.publicURL} />
                </div>
                <Name>
                  <b>{frontmatter.name}</b>
                  <br /> // {frontmatter.position}
                </Name>
              </Header>
              <div>
                <Text dangerouslySetInnerHTML={{ __html: html }} />
              </div>
            </Review>
          )
        )}
        {isOpen && data.slice(3).map(
          ({
            node: {
              childMarkdownRemark: { frontmatter, html },
            },
          }) => (
            <Review key={frontmatter.name}>
              <Header>
                <div className="image">
                  <Video src={frontmatter.image.publicURL} />
                </div>
                <Name>
                  <b>{frontmatter.name}</b>
                  <br /> // {frontmatter.position}
                </Name>
              </Header>
              <div>
                <Text dangerouslySetInnerHTML={{ __html: html }} />
              </div>
            </Review>
          )
        )}
        <MoreButton
          onClick={() => setIsOpen(s => !s)}
          visible={moreNumber}
          opened={isOpen}
        >
          {allReviews} <ArrowDown className="arrowDown" /> {!isOpen && moreNumber}
        </MoreButton>
      </div>
    </WrapperReviews>
  );
}
