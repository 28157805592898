import React, { Component } from "react";
import { graphql } from "gatsby";
import { Element } from "react-scroll";

import "../fonts/index.css";
import Layout from "../components/Layout";
import Labs from "../components/Labs";
import Projects from "../components/Projects";
import Work from "../components/Work";
import Reviews from "../components/Reviews";
import Business from "../components/Business";
import Careers from "../components/Careers";
import Contacts from "../components/Contacts";
import {
  RightLine,
  ButtonSend,
  ModalBackground,
  Content
} from "../components/style";
import { BlogDesktop, BlogWide } from "../components/Blog";
import { Modal } from "../components/Form";

class IndexPage extends Component {
  state = {
    modalVisible: false,
    file: "",
    text: "",
    email: "",
    sent: ""
  };

  componentDidMount() {
    this.setState({
      text: localStorage.getItem("text") || "",
      email: localStorage.getItem("email") || ""
    });
  }

  componentDidUpdate() {
    const { modalVisible } = this.state;
    const [html] = document.getElementsByTagName("html");
    if (modalVisible) {
      html.style.overflow = "hidden";
    } else {
      html.style.overflow = "";
    }
  }

  setFile = e => {
    this.setState({
      file: e.target.files.length
        ? e.target.files[0].name
        : this.props.business.data.noFile
    });
  };

  setEmail = e => {
    this.setState({ email: e.target.value });
    localStorage.setItem("email", e.target.value);
  };

  setText = e => {
    this.setState({ text: e.target.value });
    localStorage.setItem("text", e.target.value);
  };

  setSent = sending => {
    if (sending) {
      this.setState({
        sent: "sending"
      });
    } else {
      this.setState({
        sent: "sent",
        text: "",
        email: "",
        file: ""
      });
      localStorage.setItem("email", "");
      localStorage.setItem("text", "");
    }
  };

  openModal = () => {
    this.setState({ modalVisible: true });
  };

  closeModal = () => {
    this.setState({ modalVisible: false });
  };

  render() {
    const {
      pageContext: { locale },
      data: { main, labs, projects, work, reviews, business, blog, careers }
    } = this.props;
    const { modalVisible, file, email, text, sent } = this.state;
    return (
      <Layout
        locale={locale}
        data={main.data}
        buttonText={business.data.buttonText}
        newProject={projects.data.new}
        modalVisible={modalVisible}
        onClick={this.openModal}
      >
        <Content modal={modalVisible}>
          <Labs data={labs.data} locale={locale} work={work.data} />
          <Projects data={projects.data} />
          <Work data={work.data} />
          <BlogDesktop data={blog.data} start={0} number={3} />
          <BlogWide data={blog.data} start={0} number={4} />
          <Reviews
            title={main.data.reviewsTitle}
            data={reviews.data}
            allReviews={main.data.allReviews}
          />

          <Element name="form">
            <Business
              data={business.data}
              blog={blog.data}
              locale={locale}
              state={{
                file,
                email,
                text,
                sent,
                setFile: this.setFile,
                setEmail: this.setEmail,
                setText: this.setText,
                setSent: this.setSent
              }}
            />
            <BlogWide data={blog.data} start={4} number={4} />
            <Careers
              title={main.data.careersTitle}
              sendResume={main.data.sendResume}
              blog={blog.data}
              data={careers.data}
            />
            <BlogDesktop data={blog.data} start={3} number={3} />
            <Contacts
              title={main.data.contactsTitle}
              contacts={main.data.contacts}
              blog={blog.data}
            />
          </Element>
        </Content>
        <ButtonSend
          activeClass="hidden"
          to="form"
          spy
          onClick={this.openModal}
          isDynamic
          offset={-100}
        >
          {business.data.buttonText}
        </ButtonSend>
        <RightLine />
        <Modal
          visible={modalVisible}
          closeModal={this.closeModal}
          data={business.data}
          state={{
            file,
            email,
            text,
            sent,
            setFile: this.setFile,
            setEmail: this.setEmail,
            setText: this.setText,
            setSent: this.setSent
          }}
        />
        {modalVisible && <ModalBackground onClick={this.closeModal} />}
      </Layout>
    );
  }
}

export const query = graphql`
  query Index($locale: String, $reviewsPath: String, $careersPath: String) {
    main: file(name: { eq: $locale }, relativeDirectory: { eq: "main" }) {
      data: childMainYaml {
        title
        headerLinks
        reviewsTitle
        allReviews
        careersTitle
        sendResume
        contactsTitle
        contacts {
          title
          links {
            text
            url
          }
        }
      }
    }
    labs: file(name: { eq: $locale }, relativeDirectory: { eq: "labs" }) {
      data: childLabsYaml {
        title
        subtitle
        contacts {
          title
          email
          phone
        }
        paragraphs {
          begin
          text
          partners
        }
        allPartners
        partners {
          name
          description
          icon
          url
        }
      }
    }
    projects: file(
      name: { eq: $locale }
      relativeDirectory: { eq: "projects" }
    ) {
      data: childProjectsYaml {
        title
        new
        whatWeDoPrefix
        allProjects
        projects {
          name
          description
          whatWeDo
          url
        }
      }
    }
    work: file(name: { eq: $locale }, relativeDirectory: { eq: "work" }) {
      data: childWorkYaml {
        title
        weUse
        weUseText
        sections {
          title
          items {
            text
            url
          }
        }
      }
    }
    reviews: allFile(filter: { relativeDirectory: { eq: $reviewsPath } }) {
      data: edges {
        node {
          childMarkdownRemark {
            frontmatter {
              name
              position
              image {
                publicURL
              }
            }
            html
          }
        }
      }
    }
    business: file(
      name: { eq: $locale }
      relativeDirectory: { eq: "business" }
    ) {
      data: childBusinessYaml {
        title
        tel
        arrowText
        subtitle
        textHint
        fileText
        fileHint
        noFile
        emailHint
        replyInfo
        buttonText
        close
        successText
      }
    }
    careers: allFile(filter: { relativeDirectory: { eq: $careersPath } }) {
      data: edges {
        node {
          childMarkdownRemark {
            frontmatter {
              title
              url
            }
            html
          }
        }
      }
    }
    blog: file(name: { eq: $locale }, relativeDirectory: { eq: "blog" }) {
      data: childBlogYaml {
        posts {
          type
          text
          url
        }
      }
    }
  }
`;

export default IndexPage;
