import React from 'react';
import { Link } from 'react-scroll';
import styled from 'styled-components';
import Headroom from 'react-headroom';
import { colors, media, ButtonSend } from './style';

const Wrapper = styled.div`
  position: absolute;
  nav {
    position: relative;
    white-space: nowrap;
    padding: 0 20px;
    overflow-x: scroll;
    ${media.desktop`
      padding: 10px 90px 0px 35px;
      overflow-x: auto;
    `};
  }
  .headroom:after {
    background-image: linear-gradient(to right, transparent, ${colors.black});
    bottom: 0;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 10px;
  }
  .headroom:before {
    background-image: linear-gradient(to left, transparent, ${colors.black});
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    top: 0;
    width: 10px;
    z-index: 11;
  }
  .headroom {
    top: 0;
    left: 0;
    right: 0;
    zindex: 1;
    background: ${colors.black};
    box-shadow: 0px 10px 10px ${colors.black};
    position: fixed;
    z-index: 10;
  }
  .headroom--unfixed {
  }
  .headroom--scrolled {
    transition: transform 300ms ease-in-out;
  }
  .headroom--unpinned {
    transform: translateY(-100%);
  }
  .headroom--pinned {
    transform: translateY(0%);
  }
  .button {
    display: inline-block;
  }
  ${p => p.button
    && media.desktop`
    .button {
      width: 300px;
    }
  `};
`;

const StyledLink = styled(({ hide, noHash, ...props }) => <Link {...props} />).attrs({
  activeClass: 'active',
  offset: p => p.offset || -35,
  duration: 300,
  spy: true,
  smooth: true,
  hashSpy: p => !p.noHash,
})`
  display: inline-block;
  font-family: Ubuntu Mono;
  font-style: normal;
  font-weight: normal;
  line-height: 30px;
  font-size: 11px;
  text-transform: uppercase;
  cursor: pointer;

  color: ${colors.blue};

  padding-left: 13px;
  :first-child {
    padding-left: 0;
  }
  word-spacing: -0.25em;
  ${media.desktop`
    :hover {
      color: ${colors.red};
      text-shadow: 0px 0px 40px rgba(233, 25, 33, 0.8);
    }
  `};

  &.active {
    font-weight: bold;
    color: ${colors.white};
    text-shadow: none;
  }

  .red {
    color: ${colors.red};
  }

  ${media.desktop`
    line-height: 50px;
    font-size: 24px;
    ${p => p.hide && 'display: none;'}
    padding-left: 20px;
    :first-child {
      padding-left: 0px;
    }
  `};
`;

const ButtonSendD = styled(ButtonSend)`
  display: none;
  ${media.desktop`
    display: block;
    position: fixed;
    right: 90px;
    top: 10px;
    bottom: 10px;
    left: auto;
  `};
`;

const Header = ({
  links, buttonText, newProject, onClick, modalVisible,
}) => (
  <Wrapper button={!modalVisible}>
    <Headroom
      disableInlineStyles
      downTolerance={30}
      disable={typeof window !== 'undefined' && window.innerWidth >= 1000}
    >
      <nav>
        <StyledLink to="labs" offset={-45} noHash>
          {links[0]}
        </StyledLink>
        <StyledLink to="projects">
          {newProject && <span className="red">•</span>}
          {links[1]}
        </StyledLink>
        <StyledLink
          hide
          to="work"
          noHash={typeof window !== 'undefined' && window.innerWidth >= 1000}
        >
          {links[2]}
        </StyledLink>
        <StyledLink to="reviews">{links[3]}</StyledLink>
        <StyledLink to="business">{links[4]}</StyledLink>
        <StyledLink to="careers">{links[5]}</StyledLink>
        <StyledLink to="contacts">{links[6]}</StyledLink>
        {!modalVisible && (
          <div className="button">
            <ButtonSendD
              activeClass="hidden"
              to="form"
              spy
              onClick={onClick}
              isDynamic
              offset={-100}
            >
              {buttonText}
            </ButtonSendD>
          </div>
        )}
      </nav>
    </Headroom>
  </Wrapper>
);

export default Header;
