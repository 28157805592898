import React, { Component } from 'react';
import styled from 'styled-components';
import { media, colors } from './style';
import * as icons from './icons';

const { ArrowDown } = icons;

const More = styled.div`
  ${p => !p.opened
    && media.desktop`
        display: none;
    `};
`;

const Partner = ({
  name, description, icon, url,
}) => {
  const Icon = icons[icon] || React.Fragment;
  return (
    <div key={name} className="partner">
      <a href={url}>
        <Icon />{' '}
        <span>
          {name} // {description}
        </span>
      </a>
    </div>
  );
};

const MoreButton = styled.div`
  display: none;
  cursor: pointer;
  .arrowDown {
    width: 20px;
    path {
      stroke: ${colors.gray};
    }
  }
  ${p => p.visible
    && media.desktop`
    display: block;
  `};
  ${p => p.opened
    && `
    .arrowDown {
      transform: rotate(180deg);
    }
  `};
`;

const Wrapper = styled.div`
  font-family: Roboto;
  font-weight: 300;
  line-height: 40px;
  font-size: 30px;
  color: ${colors.gray};
  .partner {
    display: inline-block;
  }
  span {
    display: none;
  }
  svg {
    padding-right: 10px;
    height: 20px;
  }
  svg path {
    fill: ${colors.gray};
  }
  a {
    color: ${colors.gray};
    text-decoration: none;
  }
  ${media.desktop`
    .partner {
      display: block;
    }
    svg {
      padding-right: 0;
      height: 30px;
      position: relative;
      top: 5px;
    }
    span {
      display: inline;
    }
  `};
`;

export default class Partners extends Component {
  state = {
    opened: false,
  };

  render() {
    const { data, allPartners } = this.props;
    const { opened } = this.state;
    const moreNumber = data.slice(3).length;
    return (
      <Wrapper>
        {data.slice(0, 3).map(Partner)}
        <More opened={opened}>{data.slice(3).map(Partner)}</More>
        <MoreButton
          onClick={() => this.setState(s => ({ opened: !s.opened }))}
          visible={moreNumber}
          opened={opened}
        >
          {allPartners} <ArrowDown className="arrowDown" /> {!opened && moreNumber}
        </MoreButton>
      </Wrapper>
    );
  }
}
