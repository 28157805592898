import React from 'react';
import styled from 'styled-components';
import {
  Wrapper, Title, colors, media,
} from './style';
import { ArrowDown } from './icons';

const WrapperWork = styled(({ labs, opened, ...props }) => <Wrapper {...props} />)`
  padding-top: 50px;
  padding-bottom: 15px;
  ${Title} {
    margin-bottom: 19px;
  }
  .arrowDown {
    width: 18px;
  }
  ${p => p.opened
    && `
    .arrowDown {
      transform: rotate(180deg);
    }
  `};
  ${media.desktop`
    ${p => !p.labs && 'display: none;'}
    ${Title} {
      display: none;
    }
    z-index: 2;
    position: relative;
  `};
`;

const Text = styled.div`
  font-family: Ubuntu Mono;
  font-style: normal;
  font-weight: normal;
  line-height: 15px;
  font-size: 11px;
  text-transform: uppercase;
  margin-bottom: 25px;

  .red {
    color: ${colors.red};
  }

  a {
    color: white;
    text-decoration: none;
    display: inline-block;
  }

  .arrow {
    font-family: Poteha;
    line-height: normal;
    font-size: 20px;
    text-align: center;
    text-transform: uppercase;

    color: ${colors.blue};
  }

  .avail {
    display: none;
    height: 94px;
    width: 100%;
    position: relative;
    svg {
      z-index: -1;
      position: absolute;
      top: -40px;
      left: -40px;
    }
  }

  ${media.desktop`
    line-height: 25px;
    font-size: 16px;
    a:hover .arrow {
      color: ${colors.red};
    }
    .avail {
      display: block;
    }
    ${p => p.avail
      && `
      .title {
        display: none;
      }
    `}
  `};
`;

const More = styled.div`
  display: none;
  ${p => p.opened
    && `
        display: block;
    `};
  ${media.desktop`
    display: flex;
    flex-direction: column;
    ${Text}:nth-child(1) { order: 1; }
    ${Text}:nth-child(2) { order: 2; }
    ${Text}:nth-child(3) { order: 4; }
    ${Text}:nth-child(4) { order: 3; }
  `};
`;

export default class Work extends React.Component {
  state = {
    opened: false,
  };

  render() {
    const { data, labs } = this.props;
    const { opened } = this.state;
    return (
      <WrapperWork name="work" labs={labs} opened={opened}>
        <Title onClick={() => this.setState(s => ({ opened: !s.opened }))}>
          {data.title} <ArrowDown className="arrowDown" />
        </Title>
        <More opened={opened}>
          <Text>
            <b>{data.weUse}</b> {data.weUseText}
          </Text>
          {data.sections.map(({ title, items }, i) => (
            <Text key={title} avail={i === 2}>
              <b className="title">{title}</b>
              {i === 2 && (
                <div className="avail">
                  <svg
                    height="174"
                    viewBox="0 0 334 174"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g filter="url(#filter4_d)">
                      <path
                        d="M42 90L97.5 57L110 76.5L66 115H90.5M142.5 54.5L119.5 95L187.5 82.5M164 54.5L131 117M222.2 41L178 132.5M244 57H268.5L236 117M221.5 82.5L293 96"
                        stroke="#E91921"
                        strokeWidth="4"
                        strokeLinejoin="round"
                      />
                    </g>
                    <defs>
                      <filter
                        id="filter4_d"
                        x="0"
                        y="0"
                        width="334"
                        height="174"
                        filterUnits="userSpaceOnUse"
                        colorInterpolationFilters="sRGB"
                      >
                        <feFlood floodOpacity="0" result="BackgroundImageFix" />
                        <feColorMatrix
                          in="SourceAlpha"
                          type="matrix"
                          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        />
                        <feOffset />
                        <feGaussianBlur stdDeviation="20" />
                        <feColorMatrix
                          type="matrix"
                          values="0 0 0 0 0.913725 0 0 0 0 0.0980392 0 0 0 0 0.129412 0 0 0 0.8 0"
                        />
                        <feBlend
                          mode="lighten"
                          in2="BackgroundImageFix"
                          result="effect1_dropShadow"
                        />
                        <feBlend
                          mode="normal"
                          in="SourceGraphic"
                          in2="effect1_dropShadow"
                          result="shape"
                        />
                      </filter>
                    </defs>
                  </svg>
                </div>
              )}
              <br />
              {items.map(({ text, url }) => (
                <div key={text}>
                  {url ? (
                    <a href={url}>
                      <span className="red">//</span>{' '}
                      <span dangerouslySetInnerHTML={{ __html: text }} />{' '}
                      <span className="arrow">→</span>
                    </a>
                  ) : (
                    <React.Fragment>
                      <span className="red">//</span>{' '}
                      <span dangerouslySetInnerHTML={{ __html: text }} />
                    </React.Fragment>
                  )}
                </div>
              ))}
            </Text>
          ))}
        </More>
      </WrapperWork>
    );
  }
}
