import React from 'react';
import styled from 'styled-components';
import {
  Wrapper, Title, Button, colors, media,
} from './style';
import Blog from './Blog';

const WrapperC = styled(Wrapper)`
  position: relative;
  .row {
    display: flex;
    flex-direction: column;
  }
  .row .list {
    display: none;
  }
  ${media.desktop`
    padding-top: 135px;
    .row {
      flex-direction: row;
      padding-top: 45px;
      padding-bottom: 40px;
    }
    .row .column {
      padding-left: 60px;
      flex-shrink: 0;
    }
    .row .list {
      display: block;
      padding-bottom: 15px;
    }
    ${Title} {
      padding-right: 280px;
    }
  `};
`;

const Text = styled.div`
  position: relative;
  font-family: Roboto;
  font-weight: 300;
  line-height: 20px;
  font-size: 17px;
  display: flex;
  flex-direction: column;

  div {
    margin-bottom: 1.45rem;
  }

  color: ${colors.gray};
  b,
  strong {
    color: ${colors.white};
  }
  ${media.desktop`
    line-height: 40px;
    font-size: 30px;
  `};
  ${media.wide`
    flex-direction: row;
    div {
      flex: 1;
    }
    div:first-child {
      margin-right: 50px;
    }
  `};
`;

const List = styled.div`
  background: ${colors.black};
  position: relative;
  margin: 0 -20px;
  .list {
    overflow-x: scroll;
    padding: 10px 20px 20px;
    white-space: nowrap;
  }
  :after {
    background-image: linear-gradient(to right, transparent, ${colors.black});
    bottom: 0;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 10px;
  }
  :before {
    background-image: linear-gradient(to left, transparent, ${colors.black});
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    top: 0;
    width: 10px;
    z-index: 11;
  }
  ${media.desktop`
    display: none;
  `};
`;

const Item = styled.div`
  display: inline-block;
  font-family: Ubuntu Mono;
  font-style: normal;
  font-weight: normal;
  line-height: 15px;
  font-size: 11px;
  text-transform: uppercase;
  cursor: pointer;

  color: ${colors.blue};

  padding-left: 13px;
  :first-child {
    padding-left: 0;
  }
  ${media.desktop`
    :hover {
      color: ${colors.red};
      text-shadow: 0px 0px 40px rgba(233, 25, 33, 0.8);
    }
    font-weight: bold;
  `};
  &.active {
    font-weight: bold;
    color: ${colors.white};
    text-shadow: none;
  }

  ${media.desktop`
    line-height: 25px;
    font-size: 16px;
    display: block;
    padding-left: 0;
    padding-bottom: 15px;
  `};
`;

const BlogC = styled(Blog)`
  padding-top: 40px;
  ${media.desktop`
    display: none;
  `};
`;

const ButtonC = styled(Button)`
  ${media.desktop`
    padding: 4px 50px;
    :active {
      padding: 4px 50px;
    }
  `};
`;

export default class Careers extends React.Component {
  state = {
    index: 0,
  };

  componentDidMount() {
    const qs = window.location.search.slice(1);
    const index = this.props.data.findIndex(
      ({ node }) => node.childMarkdownRemark.frontmatter.url === qs
    );
    if (index !== -1) {
      this.setState({ index });
    }
  }

  changeItem = (index, url) => {
    this.setState({ index });
    if (window.history.pushState) {
      const newurl = `${window.location.protocol}//${window.location.host}${
        window.location.pathname
      }?${url}${window.location.hash}`;
      window.history.pushState({ path: newurl }, '', newurl);
    }
  };

  render() {
    const {
      title, sendResume, data, blog,
    } = this.props;
    const { index } = this.state;
    return (
      <WrapperC id="careers">
        <Title>{title}</Title>
        <List>
          <div className="list">
            {data.map(({ node }, i) => {
              const t = node.childMarkdownRemark.frontmatter.title;
              return (
                <Item
                  key={t}
                  className={i === index && 'active'}
                  onClick={() => this.changeItem(i, node.childMarkdownRemark.frontmatter.url)}
                >
                  {t}
                </Item>
              );
            })}
          </div>
        </List>
        <div className="row">
          <Text dangerouslySetInnerHTML={{ __html: data[index].node.childMarkdownRemark.html }} />
          <div className="column">
            <div className="list">
              {data.map(({ node }, i) => {
                const t = node.childMarkdownRemark.frontmatter.title;
                return (
                  <Item
                    key={t}
                    className={i === index && 'active'}
                    onClick={() => this.changeItem(i, node.childMarkdownRemark.frontmatter.url)}
                  >
                    {t}
                  </Item>
                );
              })}
            </div>
            <ButtonC
              onClick={() => {
                window.open('https://goo.gl/forms/wFWbYdIP1beda17r1');
              }}
            >
              {sendResume}
            </ButtonC>
          </div>
        </div>
        <BlogC data={blog} start={1} number={2} />
      </WrapperC>
    );
  }
}
