import React, { Component } from 'react';
import styled from 'styled-components';
import Textarea from 'react-autosize-textarea';
import ScrollLock from 'react-scrolllock';
import { Button, colors, media } from './style';
import { ArrowDown } from './icons';

const TextHint = styled.div`
  font-family: Roboto;
  font-weight: 300;
  line-height: 20px;
  font-size: 17px;

  color: ${colors.gray};
  padding-top: 20px;
  ${media.desktop`
    line-height: 50px;
    font-size: 44px;
    padding-top: 35px;
  `};
`;

const TextInputWrapper = styled.div`
  background: #212126;
  width: 100%;
  border-bottom: 1.1px solid ${colors.white};
  ${media.desktop`
    border-bottom-width: 2px;
  `};
`;

const TextInput = styled(Textarea)`
  line-height: 15px;
  font-size: 11px;
  resize: none;
  background: #212126;
  border: 0;
  height: 100px;
  width: 100%;
  font-family: Ubuntu Mono;
  font-style: normal;
  font-weight: normal;
  line-height: 15px;
  font-size: 11px;
  color: ${colors.white};
  padding: 10px 10px 15px;
  box-sizing: border-box;
  :focus {
    outline: none !important;
  }
  ${media.desktop`
    font-family: Roboto;
    font-weight: 300;
    line-height: 40px;
    font-weight: 300;
    font-size: 30px;
    padding: 20px 20px 40px;
    height: 180px;
  `};
`;

const FileInput = styled.div`
  padding-top: 20px;
  font-family: Roboto;
  font-weight: 300;
  line-height: 20px;
  font-size: 17px;

  color: ${colors.gray};
  label {
    display: inline-block;
    background: #16161b;
    border: 1px solid ${colors.white};
    box-sizing: border-box;
    box-shadow: 0px 3px 6px ${colors.white}, inset 0px 0px 40px ${colors.white};
    border-radius: 4px;
    padding: 4px 15px;
    font-family: Ubuntu Mono;
    font-style: normal;
    font-weight: normal;
    line-height: 20px;
    font-size: 11px;
    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    margin-right: 10px;
    cursor: pointer;
    user-select: none;
    :active {
      color: ${colors.red};
      box-shadow: 0px 4px 10px rgba(233, 25, 33, 0.8), inset 0px 0px 40px rgba(233, 25, 33, 0.8);
      border: 1px solid ${colors.red};
    }
    ${media.desktop`
      :hover {
        color: ${colors.red};
        box-shadow: 0px 4px 8px rgba(233, 25, 33, 0.4), inset 0px 0px 40px rgba(233, 25, 33, 0.6);
        border: 2.2px solid ${colors.red};
      }
    `};

    color: ${colors.white};
    ${media.desktop`
      padding: 4px 30px;
      line-height: 40px;
      font-size: 24px;
      margin-right: 24px;
      & {
        border-width: 2.2px;
      }
    `};
  }
  label input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
  .fileName {
    font-family: Ubuntu Mono;
    font-style: normal;
    font-weight: normal;
    line-height: 15px;
    font-size: 11px;
    text-transform: uppercase;
    padding-top: 10px;
    ${media.desktop`
      line-height: 25px;
      font-size: 16px;
    `};
  }

  ${media.desktop`
    line-height: 50px;
    font-size: 44px;
  `};
`;

const EmailHint = styled(TextHint)`
  padding-top: 15px;
`;

const EmailInput = styled.input.attrs({ type: 'text' })`
  background: #212126;
  border: 0;
  border-bottom: 1.1px solid ${colors.white};
  width: 100%;
  font-family: Roboto;
  line-height: 15px;
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: 15px;
  font-size: 11px;
  color: ${colors.white};
  padding: 10px;
  border-radius: 0px;
  :focus {
    outline: none !important;
  }
  ${p => !p.valid
    && `
    border-bottom: 1.1px solid ${colors.red};
  `};
  ${media.desktop`
    line-height: 40px;
    font-size: 30px;
    max-width: 600px;
    border-bottom-width: 2px;
  `};
`;

const ReplyInfo = styled.div`
  font-family: Ubuntu Mono;
  font-style: normal;
  font-weight: normal;
  line-height: 15px;
  font-size: 11px;
  text-align: center;
  text-transform: uppercase;

  color: ${colors.gray};

  padding-top: 15px;
  ${media.desktop`
    line-height: 25px;
    font-size: 16px;
    text-align: left;
    width: 120px;
    padding-top: 0;
    padding-left: 20px;
  `};
`;

const FormWrapper = styled.div`
  ${p => !p.modal
    && media.wide`
      padding-right: 420px;
    `};
  .row {
    display: flex;
    flex-direction: row;
  }
  .subtitle {
    display: none;
  }
  .inputs {
    flex-grow: 1;
  }
  .button {
    display: flex;
    flex-direction: column;
  }
  .close {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  ${media.desktop`
    .subtitle {
      display: block;
      font-family: Roboto;
      font-weight: 300;
      line-height: 40px;
      font-size: 30px;
      padding-left: 40px;
      color: ${colors.gray};
      width: 300px;
      position: relative;
      top: -10px;
    }
    .button {
      flex-direction: row-reverse;
      justify-content: flex-end;
      padding-top: 50px;
    }
  `};
`;

const Close = styled.div`
  display: none;
  font-family: Ubuntu Mono;
  font-style: normal;
  font-weight: normal;
  line-height: 25px;
  font-size: 16px;
  text-transform: uppercase;
  cursor: pointer;

  color: #157efa;
  :hover {
    color: ${colors.red};
    .arrowDown path {
      stroke: ${colors.red};
    }
  }
  .arrowDown {
    height: 10px;
    path {
      stroke: #157efa;
    }
  }
  ${media.desktop`
    display: block;
  `};
`;

const CloseMobile = styled.div`
  width: 20px;
  height: 3px;
  background: #c4c4c4;
  border-radius: 11111px;
  ${media.desktop`
    display: none;
  `};
`;

const SuccessText = styled.div`
  font-family: Ubuntu Mono;
  font-style: normal;
  font-weight: normal;
  line-height: 30px;
  font-size: 28px;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(
    to right,
    ${colors.red} 0%,
    ${colors.blue} 80.66%,
    ${colors.blue} 100%
  );
  ${media.desktop`
    font-size: 80px;
    line-height: normal;
  `};
`;

const Form = ({
  data, modal, closeModal, state, sRef,
}) => (
  <FormWrapper modal={modal}>
    {modal && (
      <div className="close">
        <Close onClick={closeModal}>
          {data.close} <ArrowDown className="arrowDown" />
        </Close>
        <CloseMobile />
      </div>
    )}
    {state.sent === 'sent' ? (
      <SuccessText dangerouslySetInnerHTML={{ __html: data.successText }} />
    ) : (
      <React.Fragment>
        <TextHint>{data.textHint}</TextHint>
        <div className="row">
          <div className="inputs">
            <div className="scroll" ref={sRef}>
              <TextInputWrapper>
                <TextInput async onChange={state.setText} value={state.text} />
              </TextInputWrapper>
              <FileInput>
                <label htmlFor="file">
                  {data.fileText}
                  <input id="file" type="file" onChange={state.setFile} />
                </label>
                {data.fileHint}
                <div className="fileName">{state.file}</div>
              </FileInput>
              <EmailHint>{data.emailHint}</EmailHint>
              <EmailInput valid onChange={state.setEmail} value={state.email} />
            </div>
            <div className="button">
              <ReplyInfo>{data.replyInfo}</ReplyInfo>
              <Button
                sending={state.sent === 'sending'}
                disabled={!(state.text && state.email)}
                onClick={() => {
                  if (state.text && state.email && state.sent !== 'sending') {
                    state.setSent(true);
                    const formData = new FormData();
                    formData.append('text', state.text);
                    formData.append('email', state.email);
                    const file = document.getElementById('file').files[0];
                    if (file) formData.append('file', file);
                    const request = new XMLHttpRequest();
                    request.open(
                      'POST',
                      'https://a0uq0dm3h7.execute-api.eu-west-1.amazonaws.com/dev'
                    );
                    request.onload = () => {
                      state.setSent();
                      window.fbq('track', 'Lead');
                    };
                    request.send(formData);
                  }
                }}
              >
                {data.buttonText}
              </Button>
            </div>
          </div>
          <div className="subtitle">{data.subtitle}</div>
        </div>
      </React.Fragment>
    )}
  </FormWrapper>
);

const ModalWrapper = styled.div`
  visibility: ${p => (p.visible ? 'visible' : 'hidden')};
  position: fixed;
  bottom: 0;
  background: ${colors.black};
  right: 0;
  left: 0;
  box-shadow: 0px 0px 40px #157efa;
  border-radius: 6px 6px 0px 0px;
  z-index: 20;
  padding: 20px;
  padding-top: 10px;
  transform: translateY(${p => (p.visible ? '0%' : '100%')});
  transition: all 0.2s ease-in-out;
  max-height: 90%;
  overflow-y: auto;
  ${media.desktop`
    left: 90px;
    right: 90px;
    padding: 30px;
    padding-top: 10px;
  `};
`;

export class Modal extends Component {
  constructor() {
    super();
    this.modal = React.createRef();
  }

  render() {
    const {
      visible, data, closeModal, state,
    } = this.props;
    return (
      <React.Fragment>
        <ModalWrapper visible={visible}>
          <Form modal data={data} sRef={this.modal} closeModal={closeModal} state={state} />
        </ModalWrapper>
        {visible
          && this.modal.current
          && (typeof window !== 'undefined' && window.innerWidth < 1000 ? (
            <ScrollLock />
          ) : (
            <ScrollLock touchScrollTarget={this.modal.current} />
          ))}
      </React.Fragment>
    );
  }
}

export default Form;
