import React from 'react';
import Helmet from 'react-helmet';
import styled, { createGlobalStyle } from 'styled-components';
import { ParallaxProvider } from 'react-scroll-parallax';

import Header from './Header';
import { colors } from './style';
import banner from '../images/og_banner.png';
import potehaFont from '../fonts/Poteha-Regular.otf';
import helveticaFont from '../fonts/HelveticaNeue.ttf';

const bannerUrl = `https://potehalabs.com${banner}`;

const GlobalStyle = createGlobalStyle`
  body {
    background: ${colors.black};
    color: ${colors.white};
  }
  html {
    overflow-y: visible;
  }
`;

const Wrapper = styled.div`
  position: relative;
  overflow-x: hidden;
`;

const Layout = ({
  children, data, buttonText, newProject, onClick, modalVisible,
}) => (
  <ParallaxProvider>
    <Wrapper>
      <GlobalStyle />
      <Helmet>
        {/* Global site tag (gtag.js) - Google Analytics  */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-50716317-5" />
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-50716317-5');
          `}
        </script>

        <title>{data.title}</title>
        <meta name="title" content={data.title} />
        <meta
          name="description"
          content="Natural language processing and machine learning laboratory"
        />
        <meta property="og:site_name" content={data.title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://potehalabs.com" />

        <meta property="og:title" content={data.title} />
        <meta
          property="og:description"
          content="Natural language processing and machine learning laboratory"
        />
        <meta property="og:image" content={bannerUrl} />
        <meta name="twitter:image" content={bannerUrl} />
        <meta property="vk:image" content={bannerUrl} />
        {/* <link rel="image_src" href={bannerUrl} /> */}

        <meta
          property="description"
          content="Natural language processing and machine learning laboratory"
        />

        <meta property="fb:pages" content="225510078046884" />
        <script>
          {`
          var language = window.navigator.userLanguage || window.navigator.language;
          if (window.location.pathname === '/' && language.substr(0, 2) === 'en') {
            window.location.replace('/en/' + window.location.search + window.location.hash);
          }`}
        </script>
      </Helmet>
      <Header
        links={data.headerLinks}
        buttonText={buttonText}
        newProject={newProject}
        onClick={onClick}
        modalVisible={modalVisible}
      />
      {children}
    </Wrapper>
  </ParallaxProvider>
);

export default Layout;
