import React from "react";
import styled from "styled-components";
import { Wrapper, Title, colors, media } from "./style";

const WrapperContacts = styled(Wrapper)`
  padding: 0;
  ${media.desktop`
    padding: 0;
  `};
`;

const WrapperFirst = styled.div`
  padding: 40px 20px 10px;
  ${media.desktop`
    padding: 40px 90px 90px 35px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    .contacts {
      flex-grow: 1;
    }
  `};
`;

const WrapperRed = styled.div`
  background-color: ${colors.red};
  padding: 15px 20px 25px;
  line-height: 20px;
  font-size: 17px;
  cursor: pointer;
  a {
    color: ${colors.white};
    text-decoration: none;
  }
  ${media.desktop`
    padding: 40px 90px 50px 35px;
    line-height: 50px;
    font-size: 44px;
    z-index: 5;
    position: relative;
  `};
`;

const List = styled.div`
  font-family: Roboto;
  font-weight: 300;
  line-height: 20px;
  font-size: 17px;
  padding-bottom: 40px;
  ${media.desktop`
    padding-bottom: 0px;
  `};
  ${media.wide`
    display: flex;
    flex-direction: row;
  `};
`;

const Item = styled.div`
  padding-top: 15px;
  ${media.desktop`
    line-height: 50px;
    font-size: 44px;
    padding-top: 50px;
    .title {
      padding-bottom: 15px;
    }
  `};
  ${media.wide`
    flex-grow: 1;
  `};
`;

const Link = styled.a`
  font-style: normal;
  color: ${colors.blue};
  text-decoration: none;
  ${media.desktop`
    :hover {
      color: ${colors.red};
    }
  `};
`;

const Ptext = styled.a`
  display: block;
  text-decoration: none;
  font-family: Poteha;
  line-height: 14px;
  font-size: 17px;

  color: #e91921;

  text-shadow: 0px 0px 40px rgba(233, 25, 33, 0.8);
  ${media.desktop`
    display: none;
  `};
`;

export default function Contacts({ title, contacts, footer }) {
  return (
    <WrapperContacts id="contacts">
      <WrapperFirst>
        <div className="contacts">
          <Title>{title}</Title>
          <List>
            {contacts.map(({ title: t, links }) => (
              <Item key={t}>
                <div className="title">{t}</div>
                {links.map(({ text, url }) => (
                  <div key={text}>
                    <Link href={url}>{text}</Link>
                  </div>
                ))}
              </Item>
            ))}
          </List>
        </div>
      </WrapperFirst>
    </WrapperContacts>
  );
}
